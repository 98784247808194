class ApiQueue {
  constructor() {
    this.queue = [];
    this.running = false;
  }

  runNext() {
    if (this.queue.length > 0) {
      const nextCall = this.queue.shift();
      if (nextCall) {
        this.running = true;
        nextCall().then(() => {
          this.running = false;
          this.runNext();
        });
      }
    }
  }

  add(apiCall) {
    this.queue.push(apiCall);
    if (!this.running) {
      this.runNext();
    }
  }
}

export default new ApiQueue();

<script setup>
import { onMounted, ref, computed, onBeforeUnmount, watch } from 'vue';
import {storeToRefs } from 'pinia';
import { useElementVisibility } from '@vueuse/core'
import Steps from './components/Steps.vue';
import MainProduct from './components/MainProduct.vue';
import SkeletonNewProduct from './components/SkeletonNewProduct.vue';
import Summary from './components/Summary.vue';
import StatusBar from './components/StatusBar.vue'
import { t } from '@/i18n-instance'
import {closeAllModals} from '@/utils'
import { useCheckoutStore } from '@/stores/checkout.store';
import { useDataLayer } from '@/dataLayer.js';

const $ = useCheckoutStore();
const { addToCart, beginCheckout, upSellCartSelect } = useDataLayer()
const { showLoader } = storeToRefs($)

const nonStickySummary = ref(null)
const isNonStickySummaryVisible = useElementVisibility(nonStickySummary)

const firstLoad = ref(false)
watch(showLoader, (value) => {
  if (!value && !firstLoad.value) {
    firstLoad.value = true
    beginCheckout()
  }
}, { immediate: true})

onMounted(() => {
  window.webGlobe?.tooltip?.init()
  window.webGlobe?.wgEvent('addToCart', (product) => {
    $.addToCart(product)
    addToCart({
      ...product,
      item_list_id: 'checkout',
      item_list_name: 'checkout',
      item_category: product.item_category,
      item_id: `${product.item_pack}_${product.item_name}`,
      item_name: product.item_pack,
    }, {
      final_price: product.final_price,
      base_price: product.base_price,
    })
    if (product.upSellType) {
      upSellCartSelect(product, product.upSellType)
    }
    closeAllModals()
  })
})

onBeforeUnmount(() => {
  $.isCouponUpdated = false
})

const couponStatusBar = computed(() => {
  const couponMessageCode = $.data.coupon?.coupon_results?.coupon_message_code
  const erroCodesNotShowingStatusBar = [6, 5]
  if(!erroCodesNotShowingStatusBar.includes(couponMessageCode)) {
    return {
      status: couponMessageCode === 0 ? 'success' : 'error',
      message: t(`checkout.couponMessage.${couponMessageCode}`)
    }
  }
  return null
})
</script>

<template>
  <Steps :step="$.step" />
  <main class="l-page-main">
    <section class="l-page-content l-page-content--checkout">
      <div class="container">
        <div class="row gy-20 gx-25 isolate">
          <div class="col">
            <div class="l-page-aside-sticky">
              <div class="d-flex flex-column gap-10 gap-md-15">
                <StatusBar
                  v-if="$.data.coupon && $.isCouponUpdated && !$.isCouponLoading && couponStatusBar"
                  :status="couponStatusBar.status"
                  :message="couponStatusBar.message"
                />
                <MainProduct v-for="product in $.getCheckoutItems.filter(prod => prod.in_basket).slice(0, 1)" :key="product" :product="product" :is-first="true" />
                <div
                  v-if="$.data.checkout_banners_section"
                  v-html="$.data.checkout_banners_section"
                >
                </div>
                <MainProduct v-for="product in $.getCheckoutItems.filter(prod => prod.in_basket).slice(1, $.getCheckoutItems.length)" :key="product" :product="product" />
                <SkeletonNewProduct v-if="$.addingToBasket" />

                <!-- <OrderMore/> -->

                <!-- <div class="b-checkout-order-more-big">
                  <div class="row g-25">
                    <div class="col-xl-7">
                      <OrderMore/>
                    </div>
                    <div class="col-xl-5">
                      <OrderMoreBig/>
                    </div>
                  </div>
                </div> -->
                <div ref="nonStickySummary">
                  <Summary :is-mobile-sticky="false" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-checkout-asside">
            <aside class="l-page-aside-sticky">
              <Summary :is-hidden="isNonStickySummaryVisible" />
            </aside>
          </div>
        </div>
      </div>
    </section>
  </main>

</template>


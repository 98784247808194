<script setup>
import { onMounted, computed, ref } from "vue";
import { useCheckoutStore } from '@/stores/checkout.store';
import Button from '@/stories/Button.vue';
import IconInfo from './icon/info.vue';
import WarningModal from '@/components/shared/WarningModal.vue'
import MobileActionsDropdown from './MobileActionsDropdown.vue'
import Periods from './Periods.vue'
import Packs from './Packs.vue'
import IconInfoBlack from '@/components/checkout/components/icon/InfoBlack.vue'
import { getCustomRoundedNumber, getPriceLabel } from '@/utils/checkout';
import { useDataLayer } from '@/dataLayer';

const props = defineProps({
  product: Object,
})

const $ = useCheckoutStore();
const { addToCart, upSellCartSelect } = useDataLayer()

const isChecked = ref(false);

const discount = computed(() => {
  const calculatedDiscount = (((props.product.price_base - props.product.price_final) / props.product.price_base) * 100 * -1) || 0;
  return calculatedDiscount < 0 ? getCustomRoundedNumber(calculatedDiscount).toFixed(0) : getCustomRoundedNumber(calculatedDiscount);
});

const addToBasket = async () => {
  isChecked.value = true
  // Datalayer
  addToCart({
    ...props.product,
    item_list_id: 'checkout',
    item_list_name: 'checkout',
    item_category: props.product.category,
    item_id: `${props.product.update_pack}_${props.product.update_name}`,
    item_name: props.product.update_pack,
  }, {
    final_price: props.product.price_final,
    base_price: props.product.price_base,
  })
  upSellCartSelect(props.product)
  await $.addToCart({
    item_name: props.product.update_name,
    item_pack: props.product.update_pack,
    item_type: props.product.update_type,
    item_period: props.product.period,
  }, true);
}

const removeFromBasket = async () => {
  isChecked.value = false
  const silent = props.product.update_pack === 'YOLA_FREE' ? false : true
  await $.removeFromBasket({ pack: props.product.update_pack, name: props.product.update_name, silent });

}

onMounted(() => {
  if (props.product.in_basket) {
    isChecked.value = true;
  }
})

const handleCheckboxClick = () => {
  if(!props.product.remove_modal && !props.product.in_basket) {
    addToBasket()
    return
  }
  if(!props.product.remove_modal && props.product.in_basket) {
    removeFromBasket()
    return
  }
  if(props.product.remove_modal && !props.product.in_basket) {
    addToBasket()
    return
  }
}

const isReplacingPackage = computed(() => !!props.product?.to_replace)
const isHandlingPeriods = computed(() => props.product?.supported_periods && Object.keys(props.product.supported_periods).length > 1 && !isReplacingPackage.value && !props.product.hide_periods)
const isMobileActionsShown = computed(() => props.product.in_basket && (isHandlingPeriods.value || isReplacingPackage.value))

</script>

<template>
  <div class="b-checkout-order__service-item">
    <div class="b-checkout-order__service-title">
      <div class="b-checkout-order__service-check">
        <div
          class="b-form__group"
          :class="{ 'pe-none': $.updatingBasket }"
        >
          <div
            class="b-form-checkbox"
            v-bind="props.product.remove_modal && props.product.in_basket && {
              'data-bs-toggle': 'modal',
              'data-bs-target': `#removeProductModal-${product.id}`
            }"
            @click="handleCheckboxClick"
          >
            <input type="checkbox" :checked="isChecked" />
            <!-- <input type="checkbox" v-model="addRemoveCheckbox" @change="addOrRemove" :checked="addRemoveCheckbox" :id="product.update_name+product.update_pack" /> -->
            <label class="b-form-checkbox__label">
              <span v-if="product.name" v-html="product.name"></span>
            </label>
          </div>
        </div>

        <button
          class="b-form__group-button"
          v-if="product.info"
          type="button"
          data-bs-toggle="tooltip" data-bs-placement="top"
          data-bs-custom-class="custom-tooltip"
          :data-bs-title="product.info"
        >
          <IconInfo />
        </button>
      </div>
      <p class="b-checkout-order__p" v-html="product.description"></p>
    </div>
    <div v-if="product.in_basket" class="b-checkout-order__options d-none d-xl-flex">
      <Periods
        v-if="isHandlingPeriods"
        :period="product.period"
        :product-id="product.id"
        :product="product"
      />
      <Packs
        v-if="isReplacingPackage"
        :name="product.update_name"
        :pack="product.to_replace.find(pack => pack.pack === product.update_pack)"
        :supportedPacks="product.to_replace"
        :product-id="product.id"
        :product="product"
      />
    </div>
    <div class="b-checkout-order__service-badge-price">
      <div v-if="discount && !product.in_basket" class="b-checkout-order__badge">
        <div class="b-badge b-badge--twelfth">
          {{discount}}%
        </div>
      </div>
        <div
          class="b-checkout-order__price"
          :class="{ 'b-checkout-order__price--additional-in-basket': product.in_basket }"
        >
        <div class="b-checkout-order__price-inner">
          <div class="b-checkout-order__price-inner-upper">
            <span v-if="discount" class="b-checkout-order__price--old" :class="{'pulse': product.isUpdating}">{{ getPriceLabel(product.price_base) }}</span>
            <span class="b-checkout-order__price--main" v-if="product.price_final" :class="{'pulse': product.isUpdating}">{{ getPriceLabel(product.price_final) }}</span>
            <span v-else class="text-orange b-checkout-order__price--discount" :class="{'pulse': product.isUpdating}">{{ $t('checkout.price.free') }}</span>
            <IconInfoBlack
              v-if="product.price_info"
              data-bs-toggle="tooltip" data-bs-placement="top"
              data-bs-custom-class="custom-tooltip"
              :data-bs-title="product.price_info"
            />
            <MobileActionsDropdown
              v-if="isMobileActionsShown"
              :product-id="props.product.id"
              :is-handling-periods="isHandlingPeriods"
              :is-handling-licences="false"
              :is-handling-removal="false"
              :is-handling-pack="isReplacingPackage"
              :class="{ 'pe-none': $.updatingBasket }"
            />
          </div>
          <div v-if="product.price_text_under" class="b-checkout-order__price-inner-lower">
            <span class="b-checkout-order__price--info" v-html="product.price_text_under"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="b-checkout-order__service-divider"></div>
  <Teleport to="body">
    <WarningModal
      v-if="props.product.remove_modal && props.product.in_basket"
      :id="`removeProductModal-${props.product.id}`"
      :title="props.product.remove_title"
      :description="props.product.remove_desc"
      :primary-button="$t('remove')"
      :secondary-button="$t('back')"
      @on-primary-action="removeFromBasket"
      severity="warning"
    />
    <Periods
      v-if="isHandlingPeriods"
      :period="product.period"
      :product-id="product.id"
      :use-mobile-version="true"
      :product="product"
    />
    <Packs
      v-if="isReplacingPackage"
      :name="product.update_name"
      :pack="product.to_replace.find(pack => pack.pack === product.update_pack)"
      :supportedPacks="product.to_replace"
      :product-id="product.id"
      :use-mobile-version="true"
      :product="product"
    />
  </Teleport>
</template>

import { defineStore } from "pinia";
import config from "@/config";

import {
  addToBasket,
  removeFromBasket,
  getBasketData,
  updatePeriod as updatePeriodApi,
  updatePack as updatePackApi,
  updateLicenseCount as updateLicenseCountApi,
  submitOrder,
  getBillProfiles,
  getListOfRegistrants,
  setCoupon as setCouponApi
} from "@/services"

import {
  updateQueryParam,
  getQueryParam,
  simpleClone,
} from "@/utils"

import { getInvoiceLanguageFromSource } from "@/utils/user";
import { getRegistrantFromProfile } from "@/utils/checkout";
import apiQueue from "@/utils/apiQueue";

import {minBy} from "lodash"
import { useGlobalStore } from "./global.store";
import { useDataLayer } from "@/dataLayer";

let step = Number(getQueryParam('step')) || 1;
if (step < 1 || step > 5) {
  step = 1;
}

export const useCheckoutStore = defineStore("checkout", {
  state: () => ({
    data: null,
    isReady: false,
    loading: false,
    config: null,
    step,
    furthestStep: step,
    isCouponUpdated: false,
    isCouponLoading: false,

    isAddProfileOpen: false,
    currentProfileEditingOrSeekingID: null,

    billingProfiles: null,
    currentBillingProfileIDCache: null,
    currentBillingProfileID: null,
    currentBillingProfileIDToDelete: null,

    step4EditMode: null,
    listOfRegistrants: [],
    listOfTemporaryRegistrants: [],

    currentDomainRegistrantEditing: null,

    domainRegistrantsIDs: {},


    paymentTypes: {},
    paymentErrors: {},
    selectedPaymentTypeCache: 'gopay_cardpay',
    selectedPaymentType: 'gopay_cardpay',

    updatingBasket: false,
    addingToBasket: false,
    checkout0: {},
    checkout5: {},
    serverError: null,
  }),
  getters: {
    getCartItems: (state) => {
      if (!state.data?.cart_items) {
        return []
      }
      return state.data.cart_items
    },
    getCheckoutItems(state) {
      if (!state.data?.checkout_items) {
        return []
      }
      return state.data.checkout_items.map(item => {
        if(item.update_type === 'registration') {
          Object.keys(item.prices).forEach(period => {
            item.prices[period].final_price = 0
          })
          // console.log('item', item);

          /* let allSupportedPeriods = Object.keys(item.prices).map(period => item.prices[period]);
          const periodPrice = allSupportedPeriods.find(period => period.period === item.period);
          if (periodPrice?.final_price !== item.price_final) {
            const diff = item?.price_final - periodPrice?.final_price;
            allSupportedPeriods = allSupportedPeriods.map(period => {
              let final_price = period.final_price + diff;
              final_price = Number(final_price.toFixed(0));

              if (Object.is(final_price, -0)) {
                final_price = 0;
              }
              return {
                ...period,
                final_price,
              };
            })
            console.log('allSupportedPeriods', allSupportedPeriods);

          } */
        }

        if (item.subitems) {
          item.subitems = item.subitems.sort((a, b) => a.order - b.order);
        }
        return item
      });
    },
    getCheckoutDomains() {
      return this.getCheckoutItems.filter(item => ['registration', 'transfer'].includes(item.update_type))
    },
    getProduct: (state) => {
      return ({update_name, update_type}) => state.data.checkout_items.find(product => product.update_name === update_name && product.update_type === update_type)
    },
    getLowestPeriod: (state) => {
      const lowestItem = minBy(state.getCartItems, 'period');
      return lowestItem ? lowestItem.period : null;
    },
    isLowestPeriodOne: (state) => state.getLowestPeriod === 1,
    isServer : (state) => state.data?.items_not_formated?.some(item => config.servers.includes(item.pack)),
    getPaymentTypes: (state) => {
      const isApplePayAvailable = window.ApplePaySession; //&& window.ApplePaySession.canMakePayments();
      return Object.values(state.paymentTypes)
        .filter(paymentType => {
          const isUsedPaymentType = !['cdpay'].includes(paymentType?.name)

          return isUsedPaymentType &&
            paymentType.visible_API &&
            (paymentType.name !== 'gopay_applepay' || isApplePayAvailable)
        })
        .map(paymentType => {
          let isDisabled = state.isLowestPeriodOne && paymentType.name !== 'gopay_cardpay';
          if (state.isServer) {
            isDisabled = false
          }

          return {
            ...paymentType,
            isDisabled
          };
        })
        .sort((a, b) => a.order - b.order);
    },
    getSummary: (state) => {
      const $global = useGlobalStore()
      let priceFinal = 0;
      let priceBase = 0;
      const vatValue = $global.getConfig('general').vat / 100;
      const sumPrices = (item) => {
        if (!item.in_basket) return
        priceFinal += item.price_final;
        priceBase += item.price_base;
      }
      for (let item of state.getCheckoutItems) {
        sumPrices(item);
        if (item.subitems) {
          for (let subitem of item.subitems) {
            sumPrices(subitem);
            if (subitem.subitems) {
              for (let subsubitem of subitem.subitems) {
                sumPrices(subsubitem);
              }
            }
          }
        }
      }
      const vat = priceFinal * vatValue
      return {
        priceFinal,
        priceBase,
        discount: priceBase - priceFinal,
        vat,
        totalPrice: priceFinal + vat,
      };
    },
    isApiCall: (state) => state.updatingBasket,
    showLoader: (state) => state.loading || state.updatingBasket || state.addingToBasket,
    isEmptyCheckout: (state) => state?.data?.empty_checkout && !Object.keys(state?.checkout5)?.length && !state?.updatingBasket
  },
  actions: {
    async getBasketData(withItems = false) {
      this.loading = true
      try {
        const items = withItems ? this.data.items_not_formated : undefined
        const {data} = await getBasketData(items);
        this.setData(data.data)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    setData(data) {
      if (data?.checkout_items?.length) {
        data.checkout_items = data.checkout_items.map(item => {
          if(item.update_type === 'registration') {
            const periodPrice = item.prices[item.period].customer_price;
            Object.keys(item.prices).forEach(period => {
              if (periodPrice !== item.price_final) {
                const diff = item.price_final - periodPrice
                let final_price = item.prices[period].customer_price + diff;
                final_price = Number(final_price.toFixed(0));
                if (Object.is(final_price, -0)) {
                  final_price = 0;
                }
                item.prices[period].customer_price = final_price
              }
            })
          }
          return item;
        });
      }
      this.data = data;
    },
    async setCoupon(coupon) {
      try {
        this.isCouponLoading = true
        const { data } = await setCouponApi(coupon);
        this.setData(data.data)
      } catch (error) {
          console.log(error)
      } finally {
        this.isCouponLoading = false
        this.isCouponUpdated = true
      }
    },
    async addToCart(product, silent = false) {
      if (this.isApiCall && !silent) return
      let parent = null
      let prod = null
      if (!silent) {
        parent = this.findParentByDomain(product?.item_name)
      } else {
         ({parent, product: prod} = this.findProductAndParentByParams(product?.item_name, product?.item_pack))
      }
      if (parent) {
        parent.isUpdatingChild = true
        parent.isAddingChild = true
      }
      try {
        const addApiCall = async () => {
          return await addToBasket(product);
        };
        if (silent) {
          prod.in_basket = true
          return apiQueue.add(addApiCall);
        }
        this.updatingBasket = true
        const {data} = await addApiCall();
        this.setData(data.data)
        return data
      } catch (error) {
        console.log(error)
      }
      finally {
        this.addingToBasket = false
        this.updatingBasket = false
        if (parent) {
          parent.isUpdatingChild = false
          parent.isAddingChild = false
        }
      }
    },
    async removeFromBasket({ index, isFirst = false, location_source = 'cart', silent = false, pack = null, name = null }) {
      if (this.isApiCall && !silent) return
      const {parent, product} = !silent ? this.findProductAndParentById(index) : this.findProductAndParentByParams(name, pack)

      if (this.getCheckoutItems.filter(prod => prod.in_basket).length === 1 && product.update_type === 'registration') {
        silent = false
      }

      if (!silent) {
        if (!parent) {
          product.removing = true
          const removeTimeout = setTimeout(() => {
            product.removed = true
            clearTimeout(removeTimeout)
          }, 300)
        }
        if (parent) {
          parent.isUpdatingChild = true
        }
      }

      if (isFirst) {
        this.data.checkout_banners_section = null
      }


      const { removeFromCart } = useDataLayer()
      removeFromCart(product, location_source)

      try {
        const removeApiCall = async () => {
          return await removeFromBasket({ index, pack, name });
        };
        if (silent) {
          product.in_basket = false
          return apiQueue.add(removeApiCall);
        }

        this.updatingBasket = true
        const { data } = await removeApiCall();
        this.setData(data.data)
      } catch (error) {
        console.log(error)
      } finally {
        this.updatingBasket = false
        if (parent) {
          parent.isUpdatingChild = false
        }
      }
    },
    async updatePeriod({index, period, silent = true}) {
      if (this.isApiCall) return
      const {parent, product} = this.findProductAndParentById(index)
      try {
        if (product.period === period) return
        product.period = period
        const updateApiCall = async () => {
          return await updatePeriodApi({index, period});
        };
        if (silent) {
          let newPrices = product.update_type === 'registration' ? product.prices?.[period] : product?.supported_periods?.[period]
          product.price_base = newPrices?.base_price
          product.price_final = newPrices?.final_price || newPrices?.customer_price
          return apiQueue.add(updateApiCall);
        }
        product.isUpdating = true
        this.updatingBasket = true
        const {data} = await updateApiCall();
        this.setData(data.data)
      } catch (error) {
        console.log(error)
      } finally {
        this.updatingBasket = false
        product.isUpdating = false
      }
    },
    async updatePack({index, replace_pack, replace_name, silent = false, name = null, pack = null, newPrice = null}) {
      if (this.isApiCall && !silent) return
      const {parent, product} = !silent ? this.findProductAndParentById(index) : this.findProductAndParentByParams(name, pack)
      try {
        const updateApiCall = async () => {
          return await updatePackApi({ index, replace_pack, replace_name, name, pack });
        };
        if (silent) {
          product.update_pack = replace_pack
          product.price_final = newPrice
          product.price_base = newPrice
          return apiQueue.add(updateApiCall);
        }

        product.isUpdating = true
        this.updatingBasket = true
        const { data } = await updateApiCall();
        this.setData(data.data)
      } catch (error) {
        console.log(error)
      } finally {
        this.updatingBasket = false
        product.isUpdating = false
      }
    },
    async updateLicenseCount({index, count, silent = false, newPrice = null, newBasePrice = null}) {
      if (this.isApiCall && !silent) return
      const {parent, product} = this.findProductAndParentById(index)
      try {
        const updateApiCall = async () => {
          return await updateLicenseCountApi({index, count});
        };
        if (silent) {
          product.licences_count = count
          product.price_base = newBasePrice
          product.price_final = newPrice
          return apiQueue.add(updateApiCall);
        }


        this.updatingBasket = true
        const {data} = await updateApiCall();
        this.setData(data.data)
      } catch (error) {
        console.log(error)
      } finally {
        this.updatingBasket = false
      }
    },
    async getBillingProfiles() {
      try {
        const {data} = await getBillProfiles()
        this.billingProfiles = data.data
      } catch (error) {
        console.log('error', error);
      }
    },
    async getListOfRegistrants() {
      try {
        const list = await getListOfRegistrants()
        const listWithLanguageCorrection = list.map(registrant => ({
          ...registrant,
          contact: {
            ...registrant.contact,
            language: getInvoiceLanguageFromSource(registrant.owner.state)
          }
        }))

        this.listOfRegistrants = listWithLanguageCorrection
      } catch (error) {
        console.log('error', error);
      }
    },
    setStep(step) {
      this.step = step
      updateQueryParam('step', step)
      this.callDataLayer(step)
    },
    callDataLayer(step = 1) {
      const { shippingInfo, addPaymentInfo } = useDataLayer()
      if (step === 3) {
        return shippingInfo.addShippingInfo()
      } else if (step === 4) {
        return addPaymentInfo()
      }
    },
    async setPaymentsData() {
      const $global = useGlobalStore()
      this.paymentTypes = simpleClone($global.getConfig('payments'))
      this.paymentErrors = simpleClone($global.getConfig('payments_errors'))
    },
    async handleSubmitOrder() {
      const getDomainsRegdata = () => this.getCheckoutDomains
        .map(domain => {
          const domainRegistrantID = this.domainRegistrantsIDs[domain.name]

          if(!domainRegistrantID) {
            const profile = this.currentBillingProfileID
              ? this.billingProfiles.bill_profiles.find(profile => {
                return profile.IDbillprofile === this.currentBillingProfileID
              })
            : this.billingProfiles.main_profile

            return {
              id: domain.id,
              regdata: getRegistrantFromProfile(profile)
            }
          }

          const isExistingRegistrant = this?.listOfRegistrants
            ?.findIndex(registrant => registrant.IDregistrant === domainRegistrantID) !== -1

          if(!isExistingRegistrant) {
            return {
              id: domain.id,
              regdata: this?.listOfTemporaryRegistrants
                ?.find(registrant => registrant.temporaryIDregistrant === domainRegistrantID)
            }
          }

          return {
            id: domain.id,
            regdata: {
              IDregistrant: domainRegistrantID
            }
          }
      })

      const getDefaultRegData = () => {
        if(this.listOfRegistrants.length) {
          return {
            IDregistrant: this.listOfRegistrants[0].IDregistrant
          }
        }
        if(!this.currentBillingProfileID) {
          const profile = this.billingProfiles.main_profile
          return getRegistrantFromProfile(profile)
        }
        const profile = this.billingProfiles.bill_profiles
          .find(profile => profile.IDbillprofile === this.currentBillingProfileID)
        return getRegistrantFromProfile(profile)
      }

      const $global = useGlobalStore()

      const data = {
        payment: this.selectedPaymentType,
        ['billing-profile']: this.currentBillingProfileID === null
        ? this.currentBillingProfileID
        : {
          IDprofile: this.currentBillingProfileID,
        },
        items: getDomainsRegdata(), // to delete
        ['domains-regdata']: getDomainsRegdata(),
        ['default-regdata']: getDefaultRegData(),
        ['default-dns']: simpleClone($global.getConfig('default_dnsdata'))
      }

      try {
        this.serverError = null
        this.loading = true
        const response = await submitOrder(data)
        this.checkout5 = response
        if (response.data?.success) {
          const { purchase } = useDataLayer()
          const transaction_id = response.data?.data_all?.IDorder
          purchase(transaction_id)
        }

        if(response.data?.success && response.data?.data_all?.payment_link) {
          location.href = response.data?.data_all?.payment_link
          return
        }

        await this.getBasketData()
        this.loading = false
        this.setStep(5)
      } catch (error) {
        console.log(error)
        this.loading = false
        this.serverError = 'Hups. Někde nastala chyba.'
      }
    },
    setProfileAsActive(profile) {
      if(this.step4EditMode === 'domainOwner' && profile.IDregistrant) {
        this.domainRegistrantsIDs[this.currentDomainRegistrantEditing] =
          this.domainRegistrantsIDs[this.currentDomainRegistrantEditing] !== profile.IDregistrant
          ? profile.IDregistrant
          : null
        return
      }

      if(this.step4EditMode === 'domainOwner' && profile.temporaryIDregistrant) {
        this.domainRegistrantsIDs[this.currentDomainRegistrantEditing] =
          this.domainRegistrantsIDs[this.currentDomainRegistrantEditing] !== profile.temporaryIDregistrant
          ? profile.temporaryIDregistrant
          : null
        return
      }

      if(this.step4EditMode === 'billProfile' && profile.isMain) {
        this.currentBillingProfileIDCache = null
        return
      }
      if(this.step4EditMode === 'billProfile' && !profile.isMain) {
        this.currentBillingProfileIDCache = profile.IDbillprofile
        return
      }
      if(profile.isMain) {
        this.currentBillingProfileID = null
        this.currentBillingProfileIDCache = null
        return
      }
      this.currentBillingProfileID = profile.IDbillprofile
      this.currentBillingProfileIDCache = profile.IDbillprofile
    },
    findProductAndParentById(id) {
      let result = { product: null, parent: null };

      function search(items, parent = null) {
        for (let item of items) {
          if (item.id === id) {
            result.product = item;
            if (parent && parent.update_type === "registration") {
              result.parent = parent;
            }
            return true;
          }
          if (item.subitems && search(item.subitems, item)) {
            if (item.update_type === "registration") {
              result.parent = item;
            }
            return true;
          }
        }
        return false;
      }

      search(this.data.checkout_items);
      return result;
    },
    findParentByDomain(domain) {
      let result = null;

      function search(items) {
        for (let item of items) {
          if (item.name === domain || item.update_name === domain) {
            result = item;
            return true;
          }
        }
        return false;
      }

      search(this.data.checkout_items);
      return result;
    },
    findProductAndParentByParams(update_name, update_pack) {

      let result = { product: null, parent: null };

      function search(items, parent = null) {
        for (let item of items) {
          if (item.update_name === update_name && item.update_pack === update_pack) {
            result.product = item;
            if (parent && parent.update_type === "registration") {
              result.parent = parent;
            }
            return true;
          }
          if (item.subitems && search(item.subitems, item)) {
            if (item.update_type === "registration") {
              result.parent = item;
            }
            return true;
          }
        }
        return false;
      }

      search(this.data.checkout_items);
      return result;
    },
  },
  persist: {
    debug: true,
    paths: ['data'],
  },
});

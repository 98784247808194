<script setup>
import { ref, onMounted, computed } from 'vue';
import {getPriceLabel} from '@/utils/checkout';
import { useCheckoutStore } from '@/stores/checkout.store';
import Button from '@/stories/Button.vue';
import BlockButtonWrapper from '@/stories/block-button-wrapper/BlockButtonWrapper.vue'
import { closeAllModals } from '@/utils'

const props = defineProps({
  product: Object,
  productId: Number,
  name: String,
  pack: String,
  supportedPacks: Array,
  useMobileVersion: {
    type: Boolean,
    default: false
  }
})
const buttonRef = ref(null);
const isOpen = ref(false);

const $ = useCheckoutStore();

const updatePack = async (replace_pack) => {
  const newPrice = props.supportedPacks.find(pack => pack.pack === replace_pack).price;
  await $.updatePack({ index: props.productId, replace_pack, replace_name: props.name, pack: props.product.update_pack, name: props.product.update_name, silent: true, newPrice });
}

const isSelectable = computed(() => props.supportedPacks.length)

const modal = ref(null)

const tempPack = ref(null)

const setTempPack = (pack) => {
  tempPack.value = pack
}

onMounted(() => {
  if (buttonRef.value) {
    buttonRef.value.addEventListener('show.bs.dropdown', _event => {
      isOpen.value = true;
    })
    buttonRef.value.addEventListener('hidden.bs.dropdown', _event => {
      isOpen.value = false;
    })
  }
  if(modal.value) {
    modal.value.addEventListener('hidden.bs.modal', _event => {
      setTempPack(null)
    })
  }
})
</script>

<template>
  <!-- MOBILE PERIODS MODAL -->
  <div ref="modal" v-if="props.useMobileVersion" class="modal fade" :id="`mobilePacks-${props.productId}`" tabindex="-1" :aria-labelledby="`mobilePeriods-${props.productId}`" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-sm">
      <div class="modal-content">
        <div class="modal-body">
          <Button
            variant="link"
            color="gray"
            size="medium"
            class="modal-close"
            @click="closeAllModals"
            iconLeft='<svg xmlns="http://www.w3.org/2000/svg" fill="none"><path fill="#7D7F8A" fill-rule="evenodd" d="M21.828 6.175a1.167 1.167 0 0 1 0 1.65l-14 14a1.167 1.167 0 0 1-1.65-1.65l14-14a1.167 1.167 0 0 1 1.65 0Z" clip-rule="evenodd"/><path fill="#7D7F8A" fill-rule="evenodd" d="M6.178 6.175a1.167 1.167 0 0 1 1.65 0l14 14a1.167 1.167 0 1 1-1.65 1.65l-14-14a1.167 1.167 0 0 1 0-1.65Z" clip-rule="evenodd"/></svg>'
          />
          <div class="modal-checkout-options">
            <div class="modal-checkout-options__title">{{ $t('checkout.periods.title') }}</div>
            <div class="modal-checkout-options__items">
              <div
                v-for="pack of supportedPacks"
                :key="`mobile-${pack.pack}`"
                class="b-checkout-order__option"
                :class="{ active: tempPack ? pack.pack === tempPack : props.pack.pack === pack.pack }"
                @click="setTempPack(pack.pack)"
              >
                <div class="b-checkout-order__option-title">{{ pack.replace_msg }}</div>
                <div class="b-checkout-order__option-price">
                  <span>{{ getPriceLabel(pack.price) }}</span>
                </div>
              </div>
            </div>
            <BlockButtonWrapper desktopPosition="center" mobilePosition="center">
              <Button
                :label="$t('dismiss')"
                variant="outline"
                color="blue"
                size="medium"
                @click="closeAllModals"
              />
              <Button
                :label="$t('save_data')"
                variant="primary"
                color="blue"
                size="medium"
                @click="() => {
                  updatePack(tempPack)
                  closeAllModals()
                }"
              />
            </BlockButtonWrapper>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- DESKTOP DROPDOWN -->
  <div v-else class="dropdown dropdown--periods" :class="{'dropdown--opened': isOpen }">
    <button
      ref="buttonRef"
      class="b-button b-button--dropdown b-button--outline b-button--light b-button--small b-button--if-label px-10"
      :class="{ 'b-button--disabled': !isSelectable }"
      type="button"
      v-bind="isSelectable && {
        'data-bs-toggle':'dropdown',
        'aria-expanded': false
      }"
    >
      <span class="b-button__label">{{ pack.replace_msg }}</span>
    </button>
    <div v-if="isSelectable" class="dropdown-menu">
      <template v-for="pack of supportedPacks" :key="`desktop-${pack.pack}`">
        <div @click="updatePack(pack.pack)" class="b-checkout-order__option">
          <div class="b-checkout-order__option-title">{{ pack.replace_msg }}</div>
          <div class="b-checkout-order__option-price">
            <span>{{ getPriceLabel(pack.price) }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

// dataLayer.js
// import { useUserStore } from './stores/userStore';
import { useCheckoutStore } from './stores/checkout.store';
import { useAuthStore } from './stores/auth.store';
import { generateDatalayerItem, generateDatalayerCheckoutItem, generateDatalayerCheckoutItems } from './utils';

export function useDataLayer() {
  const $checkout = useCheckoutStore()
  const $auth = useAuthStore()

  const listId = {
    precheckout: {
      id: 'precheckout',
      name: 'Precheckout',
    },
    checkout: {
      id: 'checkout',
      name: 'Checkout',
    }
  }

  function precheckout() {
    const item = generateDatalayerItem({
      item_list_id: listId.precheckout.id,
      item_list_name: listId.precheckout.name,
      item_id: $checkout.checkout0.productPack,
      item_name: $checkout.checkout0.productPack,
      item_category: $checkout.checkout0.productType,
    }, {
      period: $checkout.checkout0.productPeriod,
      base_price: $checkout.checkout0.productPrices?.base_price,
      final_price: $checkout.checkout0.productPrices?.final_price,
    })
    if ($checkout.checkout0.productCount && $checkout.checkout0.productCount > 0) {
      item.licence_quantity = Number($checkout.checkout0.productCount)
    }
    window.webGlobe?.dataLayer?.sendData({
      event: 'precheckout',
      items: [item],
    })
  }

  function viewCart() {
    window.webGlobe?.dataLayer?.sendData({
      event: 'view_cart',
      value: $checkout.data.checkout_total_price_without_vat,
      items: generateDatalayerCheckoutItems($checkout.data),
    });
  }

  function modalWindowOpen(modalId) {
    window.webGlobe?.dataLayer?.sendData({
      'event':'modal_window_open',
      'modal_window_name': modalId,
    });
  }

  function modalWindowClose(modalId) {
    window.webGlobe?.dataLayer?.sendData({
      'event':'modal_window_close',
      'modal_window_name': modalId,
    });
  }

  function userAccountLoginClick() {
    window.webGlobe?.dataLayer?.sendData({
      event: $auth.isLogged ? 'user_account_click' : 'user_login_click',
    })
  }

  function changeShippingInfo() {
    window.webGlobe?.dataLayer?.sendData({
      event: 'change_shipping_info',
      coupon: $checkout.data.coupon_code,
      value: $checkout.data.checkout_total_price_without_vat,
      items: generateDatalayerCheckoutItems($checkout.data),
    })
  }

  function updateShippingInfo() {
    window.webGlobe?.dataLayer?.sendData({
      event: 'update_shipping_info',
      coupon: $checkout.data.coupon_code,
      value: $checkout.data.checkout_total_price_without_vat,
      items: generateDatalayerCheckoutItems($checkout.data),
    })
  }

  function userOpenTimePeriod(product) {
    window.webGlobe?.dataLayer?.sendData({
      event: 'user_open_time_period',
      location: listId.checkout.name,
      time_period_source: `${product?.period} months`,
      value: product?.price_final,
      items: [generateDatalayerCheckoutItem(product)],
    })
  }

  function userSelectTimePeriod(product, period, price) {
    window.webGlobe?.dataLayer?.sendData({
      event: 'user_select_time_period',
      location: listId.checkout.name,
      time_period_source: `${product?.period} months`,
      time_period_target: `${period} months`,
      value: price,
      items: [generateDatalayerCheckoutItem(product)],
    })
  }

  function addPaymentInfo() {
    const additional = {
      payment_info: $checkout.selectedPaymentType,
    }
    window.webGlobe?.dataLayer?.sendData({
      event: 'add_payment_info',
      coupon: $checkout.data.coupon_code,
      value: $checkout.data.checkout_total_price_without_vat,
      items: generateDatalayerCheckoutItems($checkout.data),
      ...additional,
    })
  }

  function changePaymentInfo(payment, oldPayment) {
    window.webGlobe?.dataLayer?.sendData({
      event: 'change_payment_info',
      coupon: $checkout.data.coupon_code,
      value: $checkout.data.checkout_total_price_without_vat,
      location: `Step ${$checkout.step}`,
      payment_info: payment,
      payment_info_source: oldPayment,
      payment_info_target: payment,
      items: generateDatalayerCheckoutItems($checkout.data),
    })
  }

  function userSelectLicenceQuantity(product, count, newProduct){
    window.webGlobe?.dataLayer?.sendData({
      event: 'user_select_licence_quantity',
      location: listId.checkout.name,
      licence_quantity_source: Number(`${product?.licences_count}`),
      licence_quantity_target: Number(`${count}`),
      value: newProduct.price_final,
      items: [generateDatalayerCheckoutItem(newProduct)],
    })
  }

  function checkoutSummary() {
    window.webGlobe?.dataLayer?.sendData({
      event: 'checkout_summary',
      coupon: $checkout.data.coupon_code,
      value: $checkout.data.checkout_total_price_without_vat,
      items: generateDatalayerCheckoutItems($checkout.data),
    })
  }

  function addToCart(product, additional = {}) {
    window.webGlobe?.dataLayer?.sendData({
      event: 'add_to_cart',
      location_source: listId.checkout.name,
      value: $checkout.data.checkout_total_price_without_vat,
      items: [generateDatalayerItem(product, additional)]
    })
  }

  function viewItemList(prefixId, prefix, items) {
    window.webGlobe?.dataLayer?.sendData({
      event: 'view_item_list',
      item_list_id: `${prefixId}check_avalability`,
      item_list_name: `${prefix}Check avalability`,
      items,
    })
  }

  function addShippingInfo() {
    window.webGlobe?.dataLayer?.sendData({
      event: 'add_shipping_info',
      coupon: $checkout.data.coupon_code,
      value: $checkout.data.checkout_total_price_without_vat,
      items: generateDatalayerCheckoutItems($checkout.data),
    })
  }

  function beginCheckout() {
    window.webGlobe?.dataLayer?.sendData({
      event: 'begin_checkout',
      coupon: $checkout.data.coupon_code,
      value: $checkout.data.checkout_total_price_without_vat,
      items: generateDatalayerCheckoutItems($checkout.data),
    })
  }

  function removeFromCart(product, location_source) {
    const items = []
    function prepareItems(product) {
      items.push(generateDatalayerCheckoutItem(product))
      if (product.subitems) {
        product.subitems.forEach(subitem => {
          if (subitem.in_basket) {
            prepareItems(subitem)
          }
        })
      }
    }
    prepareItems(product)
    window.webGlobe?.dataLayer?.sendData({
      event: 'remove_from_cart',
      location_source,
      value: Number(product.price_final),
      items,
    })
  }

  function userLoginProcess() {
    window.webGlobe?.dataLayer?.sendData({
      event: 'user_login_process',
    })
  }

  function userRegistrationProcess() {
    window.webGlobe?.dataLayer?.sendData({
      event: 'user_registration_process',
    })
  }

  function userForgotPasswordProcess() {
    window.webGlobe?.dataLayer?.sendData({
      event: 'user_forgot_password_process',
    })
  }

  function purchase(transaction_id) {
    window.webGlobe?.dataLayer?.sendData({
      event: 'purchase',
      coupon: $checkout.data.coupon_code,
      transaction_id,
      tax: $checkout.data.checkout_total_vat,
      value: $checkout.data.checkout_total_price_without_vat,
      items: generateDatalayerCheckoutItems($checkout.data),
    })
  }

  function upSellCartSelect(product, type = undefined) {
    const parentSearch = product.update_name || product.item_name
    const parent = $checkout.findParentByDomain(parentSearch)
    const upsell_item_name = `${product.update_pack || product.item_pack} ${product.update_name || product.item_name}`
    const upsell_item_id = `${product.update_pack || product.item_pack}_${product.update_name || product.item_name}`
    window.webGlobe?.dataLayer?.sendData({
      event: 'upSell_Cart_Select',
      base_item_name: `${parent?.update_pack} ${parent?.update_name}`,
      base_item_id: `${parent?.update_pack}_${parent?.update_name}`,
      base_item_category: parent?.category,
      base_item_variant: `${parent?.period} months`,
      upsell_item_name,
      upsell_item_id,
      upsell_item_category: product.category || product.item_category,
      upsell_item_variant: `${product.period || product.item_period} months`,
      upsell_item_quantity: 1,
      ...type ? {upsell_type: type} : {},
    })
  }

  function upSellCartOpen(product) {
    const parent = $checkout.findParentByDomain(product.update_name)
    window.webGlobe?.dataLayer?.sendData({
      event: 'upSell_Cart_Open',
      upSell_type: 'modal',
      base_item_name: `${parent?.update_pack} ${parent?.update_name}`,
      base_item_id: `${parent?.update_pack}_${parent?.update_name}`,
      base_item_category: parent?.category,
      base_item_variant: `${parent?.period} months`,
      upsell_item_category: product.name,
    })
  }

  function leaveCheckout(destination = 'exit', internalDestination = {}) {
    window.webGlobe?.dataLayer?.sendData({
      event: 'leaveCheckout',
      destination,
      ...internalDestination,
      leaveCount: 1,
    })
  }


  return {
    precheckout,
    viewCart,
    modalWindowOpen,
    modalWindowClose,
    userAccountLoginClick,
    shippingInfo: {
      changeShippingInfo,
      updateShippingInfo,
      addShippingInfo,
    },
    periods: {
      userOpenTimePeriod,
      userSelectTimePeriod,
    },
    changePaymentInfo,
    userSelectLicenceQuantity,
    checkoutSummary,
    addToCart,
    viewItemList,
    addPaymentInfo,
    beginCheckout,
    removeFromCart,
    userLoginProcess,
    userRegistrationProcess,
    userForgotPasswordProcess,
    purchase,
    upSellCartSelect,
    upSellCartOpen,
    leaveCheckout
  };
}
